import React from 'react';
import '../../css/projects.scss';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import HeadingDecorated from '../../components/HeadingDecorated';
import Project from '../../components/Project.en';
import Seo from '../../components/Seo';
import { LOCALIZED_PAGES } from '../nasze-projekty';

export const query = graphql`
  {
    projects: allStrapiProject(
      sort: { fields: position, order: ASC }
      filter: { locale: { eq: "en" } }
    ) {
      nodes {
        id
        avatar {
          localFile {
            url
          }
        }
        name
        details_url
        summary {
          data {
            summary
          }
        }
        slug
      }
    }
  }
`;

const ProjectsPage = ({ data: { projects } }) => {
  return (
    <Layout locale="en" localizedPages={LOCALIZED_PAGES}>
      <Seo title="Our projects" />
      <div className="projects">
        <section>
          <HeadingDecorated className="projects__section-heading">
            Our projects
          </HeadingDecorated>
          <div className="projects__section-content">
            {projects.nodes.map((item) => (
              <Project data={item} key={item.id} />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ProjectsPage;
